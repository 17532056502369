
<template>
    <OPropertiesGrid :dataObject="props.dataObject" hideOkCancel>
        <template v-for="group in propertiesGroups" :key="group.Name">
            <OPropertiesGroup :caption="$t(group.Name)">
                <OPropertiesForm :row="props.dataObject.current" viewName="aviw_Arena_ExternalDistributions">
                <template v-slot:noProperties><span></span></template>
                <template #default="{ properties, editor, renderer }">
                    <div v-for="property in properties" :key="property.name" >
                        <OPropertiesItem v-if="renderProperty(group, property)"
                            :caption="property.caption ?? property.name"
                            :title="property.title"
                            :fieldName="property.name"
                            :required="propertyIsRequired(property.name)">
                            <OPropertiesEditor :row="props.dataObject.current.propertiesRows[property.name]" class="border-0 w-100"
                                @blur="saveDataObject" wrapperClass="w-100"  v-model="props.dataObject.current.properties[property.name]"
                                :config="property" :disabled="disabledDO"/>
                        </OPropertiesItem>
                    </div>
                </template>
                </OPropertiesForm>
            </OPropertiesGroup>
        </template>
        <ODataLookup  :data-object="local_dsPropertiesLkp" :height="400" :bind="sel => handleAddProperty(sel)" :noClear="true">
            <template #target="scope" @click="scope.open">
                <h2 class="mb-0 mt-2 ms-1">
                    <button class="btn btn-sm btn-link py-0 mb-1 ps-0"  :ref="scope.target" style="font-size: 16px;"><i class="bi bi-plus-circle me-1"></i>{{$t("Add Property")}}</button>
                </h2>
            </template>
            <OColumn name="PropertyName" width="400"></OColumn>
        </ODataLookup>
    </OPropertiesGrid>
</template>
<script setup>
//NOT FOR PUBLISHING YET 
import { getOrCreateDataObject } from 'o365-dataobject';
import { ref, computed, onMounted } from 'vue';
import { OPropertiesGrid, OPropertiesItem, OPropertiesGroup }  from 'o365-data-components';
import { OPropertiesForm, OPropertiesEditor } from 'o365-data-properties';

const props = defineProps({
    dataObject: Object
})

const propertiesGroups = ref([]);

const emit = defineEmits(["propertySaved"]);    
async function saveDataObject(){
    await props.dataObject.save(); 
    await local_dsErrors.load();
    await local_ExtDistProperties.save(); //maybe not needed as it is only used for adding new records
    
    emit("propertySaved");
}

const handleAddProperty = (pSel) => {
    local_ExtDistProperties.createNew({PropertyName:pSel.PropertyName});
    local_ExtDistProperties.save().then(() => props.dataObject.load());
}


const propertyIsRequired = (propertyName) => {
    return local_ExtDistProperties.data.some(item => item.Required && item.PropertyName === propertyName);
}

const requiredFields = ref("");

const local_dsErrors = getOrCreateDataObject({
    id: 'local_dsErrors',
    viewName: 'atbv_Arena_ExternalDistributionsValidationErrors',
    appId: props.dataObject.appId,
    maxRecords: -1,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    masterDataObject_ID: props.dataObject.id,
    masterDetailDefinition: [{
        detailField: "ExternalDistribution_ID",
        masterField: "ID",
        operator: "equals"
    }],
    fields:
        [{name: "Error", type: "string"},
        {name: "FieldName", type: "string" }]
}),

local_dsPropertiesLkp = getOrCreateDataObject({
    id: 'local_dsPropertiesLkp',
    viewName: 'aviw_Arena_ExternalDistributionsAvailableProperties',
    appId: props.dataObject.appId,
    maxRecords: -1,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    fields:
        [{name: "Name", type: "string"},
        {name: "PropertyName", type: "string"},
        {name: "PropertyNameTranslated", type: "string"},
        { name: "DataType", type: "string" },
        { name: "Description", type: "date" },
        { name: "Title", type: "datetime" },]
}),

local_ExtDistProperties = getOrCreateDataObject({
    id: 'local_dsCorrProperties',
    viewName: 'aviw_Arena_ExternalDistributionsProperties2',
    appId: props.dataObject.appId,
    allowInsert:true,
    allowUpdate:true,
    allowDelete:true,
    maxRecords: -1,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    masterDataObject_ID: props.dataObject.id,
    masterDetailDefinition: [{
        detailField: "ExternalDistribution_ID",
        masterField: "ID",
        operator: "equals"
    }],
    fields:
        [{name: "PropertyName", type: "string"},
        { name: "Value", type: "string" },
        { name: "DateValue", type: "date" },
        { name: "DateTimeValue", type: "datetime" },
        { name: "IntValue", type: "number" },
        { name: "Required", type: "boolean" },
        { name: "GroupByHeader", type: "string" }]
})

const disabledDO = computed(() => {
    return  props.dataObject.current.Sent !== null;
});

const getPropertiesGroups = () => {
    propertiesGroups.value = [];
    const data =  local_ExtDistProperties.data;

    const groupHeaders = [...new Set(data.filter(item => !item.Required || !isRequiredField(item.PropertyName)).map(item => item.GroupByHeader))].sort();

    console.log(groupHeaders);
    if (data.filter(item => item.Required && !(item.Value || item.DateValue || item.DateTimeValue || item.IntValue)).map(item => item.PropertyName).length > 0) {
        propertiesGroups.value.push({ Name: "Required", SortOrder: 0, Collapsed: false });
    }

    groupHeaders.forEach((header, index) => {
        if(header === null) {
            propertiesGroups.value.push({ Name: "Properties", SortOrder: groupHeaders.length + 1, Collapsed: false });
        } else {
            propertiesGroups.value.push({ Name: header, SortOrder: index + 1, Collapsed: false });
        }
    });

    console.log(propertiesGroups.value);
};

const isRequiredField = (propertyName) => {
    const requiredFieldsSet = new Set(local_ExtDistProperties.data.filter(item => item.Required && item.PropertyName === propertyName).map(item => item.PropertyName));
    return local_dsErrors.data.some((row) => requiredFieldsSet.has(row.FieldName));
} 


const getRequiredFields = () => {
    requiredFields.value = {}
    const requiredFieldsSet = new Set(local_ExtDistProperties.data.filter(item => item.Required).map(item => item.PropertyName));

    local_dsErrors.data.map((row)=>{
        if(requiredFieldsSet.has(row.FieldName)) {
            requiredFields.value[row.PropertyName] = true;
        }
    })
}

const renderProperty = (group, property) => {
    // console.log('rendering property ', property.name)
    if(local_ExtDistProperties.state.isLoaded && local_dsErrors.state.isLoaded) {
        console.log(isRequiredField(property.name))
        if (group.Name === 'Required' && isRequiredField(property.name)) return true;
        if (group.Name !== 'Properties' && property.group === group.Name && !isRequiredField(property.name)) return true;
        if (group.Name === 'Properties' && property.group === null && !isRequiredField(property.name)) return true;
    }
  return false;
};

local_dsErrors.on('DataLoaded', () => {
    getRequiredFields();
})

// onMounted(() => {
    // local_dsErrors.on('DataLoaded', () => {
        // getRequiredFields();
    // })
    // local_ExtDistProperties.on('DataLoaded', () => {
        // getRequiredFields();
        // getPropertiesGroups();
    // })
// })

local_ExtDistProperties.on('DataLoaded', () => {
    console.log('data loaded')
    if(propertiesGroups.value.length == 0)
        getPropertiesGroups();
});

local_dsErrors.load();

</script>

<style scoped>
.spinner-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.spinner-border {
  width: 5rem;
  height: 5rem;
}

.overlay__inner{
    display:none;
}

</style>